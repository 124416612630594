// This is taken from https://marketplace.zoom.us/docs/api-reference/other-references/abbreviation-lists#timezones
// And then filtered by supported timezones in moment-timezone
export function getSupportedTimezones() {
  return [
    'Africa/Algiers',
    'Africa/Bangui',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Djibouti',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Khartoum',
    'Africa/Mogadishu',
    'Africa/Nairobi',
    'Africa/Nouakchott',
    'Africa/Tripoli',
    'Africa/Tunis',
    'America/Anchorage',
    'America/Araguaina',
    'America/Bogota',
    'America/Caracas',
    'America/Chicago',
    'America/Costa_Rica',
    'America/Denver',
    'America/Edmonton',
    'America/El_Salvador',
    'America/Godthab',
    'America/Guatemala',
    'America/Halifax',
    'America/Indianapolis',
    'America/Lima',
    'America/Los_Angeles',
    'America/Managua',
    'America/Mazatlan',
    'America/Mexico_City',
    'America/Montevideo',
    'America/Montreal',
    'America/New_York',
    'America/Panama',
    'America/Phoenix',
    'America/Puerto_Rico',
    'America/Regina',
    'America/Santiago',
    'America/Sao_Paulo',
    'America/St_Johns',
    'America/Tegucigalpa',
    'America/Tijuana',
    'America/Vancouver',
    'America/Winnipeg',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Beirut',
    'Asia/Calcutta',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dubai',
    'Asia/Hong_Kong',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Kathmandu',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuwait',
    'Asia/Magadan',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novosibirsk',
    'Asia/Qatar',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tehran',
    'Asia/Tokyo',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yekaterinburg',
    'Atlantic/Azores',
    'Atlantic/Cape_Verde',
    'Atlantic/Reykjavik',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Darwin',
    'Australia/Hobart',
    'Australia/Perth',
    'Australia/Sydney',
    'Canada/Atlantic',
    'Etc/Greenwich',
    'Europe/Amsterdam',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kiev',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Rome',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Vienna',
    'Europe/Warsaw',
    'Europe/Zurich',
    'Pacific/Auckland',
    'Pacific/Fiji',
    'Pacific/Honolulu',
    'Pacific/Midway',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Port_Moresby',
  ]
}